// Generated by Framer (c614887)

import * as React from "react";
import { motion, useOnVariantChange, useActiveVariantCallback, LayoutGroup, Image, transformTemplate, addFonts, withCSS, withGeneratedLayoutId, useBreakpointVariants } from "framer";
import { cx } from "https://framer.com/m/framer/cx.js@^1.0.0";
import { useRandomID } from "https://framer.com/m/framer/randomID.js@^2.0.0";
import { addPropertyControls, ControlType } from "https://framer.com/m/framer/PropertyControls.js@^1.0.0";
import { useAddVariantProps } from "https://framer.com/m/framer/useAddVariantProps.js@^1.0.0";
import { useVariantState, CycleVariantState } from "https://framer.com/m/framer/useVariantState.js@^1.0.0";
const Frame = withGeneratedLayoutId(motion.div);

const cycleOrder = ["LSQ3UxEhD"];

const breakpoints = {"LSQ3UxEhD": {}}

const variantClassNames = {"LSQ3UxEhD": "framer-v-yso8am"};

const humanReadableVariantMap = {};

const transitions = {"default": {"duration": 0}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; variant?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ style, className, layoutId, width, height, variant: outerVariant = "LSQ3UxEhD", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const initialVariant = useBreakpointVariants(variant, width, breakpoints);

const { variants, baseVariant, gestureVariant, classNames, transition, setVariant, setGestureState } = useVariantState({defaultVariant: "LSQ3UxEhD", variant: initialVariant, transitions, variantClassNames, cycleOrder});

const variantProps = React.useMemo(() => ({}), []);

const addVariantProps = useAddVariantProps(baseVariant, gestureVariant, variantProps);

const defaultLayoutId = useRandomID();

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={initialVariant} animate={variants} className={cx("framer-xYOJy", classNames)} style={{"display": "contents"}} >
<Frame {...restProps} layoutIdKey="LSQ3UxEhD" className={cx("framer-yso8am", className)} style={{...style}} name="Desktop" data-framer-name="Desktop" transition={transition} ref={ref} {...addVariantProps("LSQ3UxEhD")}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-xYOJy [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", ".framer-xYOJy .framer-yso8am { position: relative; overflow: hidden; width: 1200px; height: 1000px; background-color: #ffffff; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1000
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName": "variant", "data": {"default": {"layout": ["fixed", "fixed"]}}}
 * @framerResponsiveScreen
 */
const Framerscl8iwGfb: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default Framerscl8iwGfb;

Framerscl8iwGfb.displayName = "Test";

Framerscl8iwGfb.defaultProps = {"width": 1200, "height": 1000};

addFonts(Framerscl8iwGfb, []);